.mapContainer {
    box-shadow: 0 0 12px rgba(0,0,0,.4);
    overflow: hidden;
    perspective: 350px; /* TODO: consider moving this to the innerMapContainer as a transform: perspective() */
    background-color: white;
}

.innerMapContainer {
    overflow: hidden;
    position: relative;
    cursor: move;
    transform-style: preserve-3d;

}

.mapBackgroundImage {
    background-image: url(https://img.pngio.com/transparent-grid-png-free-transparent-gridpng-transparent-transparent-grid-2000_1000.png);
    background-repeat: repeat;
    background-size: 3200px;
    width: 100%;
    height: 100%;
}

.mapLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mapBGLayer {
    width:100%;
    height:100%;
}

.mapIconLayer {
}

.mapIcon {
    position:absolute;
    width:50px;
    height:50px;
    transform:translate(-50%, -50%);
    background-color:red;
}
